.servicesContainer {
  padding: 50px 210px;
}

.servicesTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 28.13px;
  text-align: left;
  width: 195px;
  padding-bottom: 5px;
  position: relative;
  border-bottom: 4px solid #e71339;
}

.seperator {
  position: absolute;
  bottom: -4px;
  left: 13px;
  height: 4px;
  width: 10px;
  background-color: #ffffff;
}

.servicesDesc {
  font-size: 16px;
  font-weight: 400;
  line-height: 44.61px;
  text-align: left;
}

.card {
  position: relative;
  /* width: 100%; */
  height: 205px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.cardImageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.cardImage {
  background-position: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
  /* object-position: -1px 0px; */
}

.cardHeader {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 150px;
  font-size: 23.13px;
  font-weight: 400;
  line-height: 27.1px;
  text-align: left;
  color: #ffffff;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: rgba(231, 19, 57, 0.5);
  transition: all 0.5s ease-in-out;
}

.cardText {
  position: absolute;
  top: 0;
  right: 40%;
  width: 60%;
  height: 100%;
  background-color: #e71339;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 0.7s ease-in-out;
  opacity: 0;
  font-size: 14.87px;
  font-weight: 400;
  line-height: 17.42px;
  text-align: left;
}

.cardArrow {
  position: absolute;
  bottom: 20px;
  left: 30px;
  transform: rotate(-45deg);
  transition: all 0.5s ease-in-out;
}

.card:hover .cardImageWrapper {
  left: 60%;
  width: 50%;
  transform: translateX(50%);
}

.card:hover .overlay {
  top: 0;
  transform: translateX(100%);
  opacity: 0;
}

.card:hover .cardImage {
  transform: translateX(-50%);
}

.card:hover .cardText {
  right: 40%;
  opacity: 1;
}

.card:hover .cardArrow {
  transform: rotate(-90deg) scale(0.6);
  bottom: 6px;
  left: 4px;
}

@media only screen and (max-width: 1300px) {
  .servicesContainer {
    padding: 50px 100px;
  }
}

@media only screen and (max-width: 640px) {
  .servicesContainer {
    padding: 50px;
  }
}

@media only screen and (max-width: 400px) {
  .servicesContainer {
    padding: 10px;
  }
}
